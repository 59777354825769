import Principal from "../util/api/util/principal";
var appSetConfig

async function loadConfig() {
    if (localStorage.getItem("appSetConfig")) {
        appSetConfig = JSON.parse(localStorage.getItem("appSetConfig"))
    } else {
        appSetConfig = await $.ajax("config.json");
        if (appSetConfig.config == undefined) {
            appSetConfig = JSON.parse(await $.ajax("config.json"));
        }
        localStorage.setItem("appSetConfig", JSON.stringify(appSetConfig))
    }
}


async function itemClick(name) {
    // if (name == "DigitalFarm") {
    //     alert("正在建设中");
    //     return;
    // }
    console.log("name",name);
    await loadConfig();
    window.location.href = appSetConfig.config.entryPageUrls[name];
}

(async function () {
    let user = Principal.get();
    if (user === null) {
        window.location.href = "login.html";
    }
    $(".card").on("click", function () {
        itemClick($(this).attr("target"))
    })
})();
